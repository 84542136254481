// Use Montserrat mixin to set font-display: block.
@use "@fontsource/montserrat/scss/mixins" as Montserrat;
@include Montserrat.fontFace(
    $fontName: 'Montserrat',
    $weight: 400,
    $display: block,
);
@include Montserrat.fontFace(
    $fontName: 'Montserrat',
    $weight: 500,
    $display: block,
);
@include Montserrat.fontFace(
    $fontName: 'Montserrat',
    $weight: 600,
    $display: block,
);
@include Montserrat.fontFaceVariable(
    $display: block,
);
